import React from 'react'
import styled from 'styled-components'

import ContactDataItem, {
  DataItem,
} from 'components/layout/Sections/Contact/ContactDataItem'
import { Heading, Text } from 'components/shared/Typography'
import { Row, Col } from 'components/shared/Grid'
import LazyImage from 'components/shared/LazyImage'

import type Image from 'types/image'

export type SaleOfficeCard = {
  image: Image
  type: string
  name: string
  list: DataItem[]
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.sm.min} {
    align-items: center;
    flex-direction: row;
    margin: 1rem 0;
  }
  ${({ theme }) => theme.media.md.min} {
    margin: 1rem 0 2rem;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -0.5rem;
    right: -0.5rem;
    background: ${({ theme }) => theme.colors.primary};
    ${({ theme }) => theme.media.md.min} {
      bottom: -1rem;
      right: -1rem;
    }
  }
  ${({ theme }) => theme.media.sm.min} {
    min-width: 170px;
    max-width: 240px;
    height: 360px;
  }
`

const ContentWrapper = styled.div`
  padding: 2rem 0 0;
  width: 100%;
  ${({ theme }) => theme.media.sm.min} {
    padding: 0.5rem 0 0.5rem 2rem;
  }
  ${({ theme }) => theme.media.lg.min} {
    padding: 1.5rem 0 1.5rem 2.5rem;
  }
`

const Address = styled.address`
  margin-top: 1rem;
  width: 100%;
  ${Col} {
    min-width: min-content;
    & > div,
    a > div {
      margin: 0;
    }
  }
`

const SaleOffice: React.FC<SaleOfficeCard> = ({ image, type, name, list }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <LazyImage src={image.src} alt={image.alt ?? ''} fill />
      </ImageWrapper>
      <ContentWrapper>
        <Text size={14} weight={500} themecolor="primary" margin="0.25rem">
          {type}
        </Text>
        <Heading
          as="h3"
          transform="uppercase"
          line="1"
          margin="0"
          size={30}
          weight={500}
          sizeDiff={0.3}
        >
          {name}
        </Heading>
        <Address>
          <Row gap="1rem">
            {list.map(({ value, label, icon }) => (
              <Col xs={24} xxl={12} key={value + label}>
                <ContactDataItem label={label} icon={icon} value={value} />
              </Col>
            ))}
          </Row>
        </Address>
      </ContentWrapper>
    </Wrapper>
  )
}

export default SaleOffice
