import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/Container'
import { Row, Col } from 'components/shared/Grid'

import SaleOffice, { SaleOfficeCard } from './SingleCard'

type Props = {
  offices: SaleOfficeCard[]
}

const Section = styled.section`
  padding-bottom: 2rem;
  ${({ theme }) => theme.media.lg.min} {
    padding-bottom: 5rem;
    & > ${Container} > ${Row} > ${Col} {
      border-bottom: 1px solid ${({ theme }) => theme.colors.white100};
    }
  }
`

const SaleOfficeSection: React.FC<Props> = ({ offices }) => {
  return (
    <Section>
      <Container>
        <Row>
          {offices.map(({ image, type, name, list }) => (
            <Col xs={24} lg={12} key={name}>
              <SaleOffice image={image} type={type} name={name} list={list} />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default SaleOfficeSection
