import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header from 'components/layout/Headers/Secondary'
import HeaderFilters from 'components/layout/Headers/Secondary/Filters'
import ContactIntro from 'components/layout/Sections/Contact/Intro'
import SaleOfficeSection from 'components/layout/Sections/Contact/SaleOffices'
import FormSection from 'components/layout/Sections/Contact/FormSection'

import { CONTACT_LINKS } from 'constants/links'

import type { ContactOfficesPageQuery } from 'types/graphql'
import type { SaleOfficeCard } from 'components/layout/Sections/Contact/SaleOffices/SingleCard'

const ContactOffices: React.FC<PageProps<ContactOfficesPageQuery>> = ({
  data,
}) => {
  const { page } = data

  const PAGE = page?.pageSalesOffice
  const PAGE_SEO = page?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const SALE_OFFICES: SaleOfficeCard[] = useMemo(
    () =>
      PAGE.salesOfficePageS2List?.map((office) => ({
        name: office?.salesOfficePageS2ListElementTitle!,
        type: office?.salesOfficePageS2ListElementBeforeTitle!,
        image: {
          src: office?.salesOfficePageS2ListElementImg?.localFile
            ?.childImageSharp?.gatsbyImageData!,
          alt: office?.salesOfficePageS2ListElementImg?.altText!,
        },
        list:
          office?.salesOfficePageS2ListElementContactDetailsList?.map(
            (detail) => ({
              icon: detail
                ?.salesOfficePageS2ListElementContactDetailsListElementImg
                ?.localFile?.publicURL!,
              value:
                detail?.salesOfficePageS2ListElementContactDetailsListElementDescription!,
              label:
                detail?.salesOfficePageS2ListElementContactDetailsListElementTitle!,
            })
          ) || [],
      })) || [],
    []
  )

  return (
    <Layout>
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'Kontakt' }]} />
      <Header
        title={PAGE.salesOfficePageS1Title!}
        image={{
          src: PAGE.salesOfficePageS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.salesOfficePageS1Img?.altText!,
        }}
      />
      <main>
        <HeaderFilters options={CONTACT_LINKS} />
        <ContactIntro
          title={PAGE.salesOfficePageS2Title!}
          description={PAGE.salesOfficePageS2Description!}
          icon={PAGE.salesOfficePageS2Img?.localFile?.publicURL!}
        />
        <SaleOfficeSection offices={SALE_OFFICES} />
        <FormSection
          title={PAGE.salesOfficePageS3Title!}
          formID="sales-offices-form"
          area
          rooms
        />
      </main>
    </Layout>
  )
}

export default ContactOffices

export const query = graphql`
  query ContactOfficesPage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/biura-sprzedazy/" }
    ) {
      seo {
        ...WpSEO
      }
      pageSalesOffice {
        salesOfficePageS1Title
        salesOfficePageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        salesOfficePageS2Title
        salesOfficePageS2Description
        salesOfficePageS2Img {
          localFile {
            publicURL
          }
        }
        salesOfficePageS2List {
          salesOfficePageS2ListElementTitle
          salesOfficePageS2ListElementBeforeTitle
          salesOfficePageS2ListElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 700
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          salesOfficePageS2ListElementContactDetailsList {
            salesOfficePageS2ListElementContactDetailsListElementTitle
            salesOfficePageS2ListElementContactDetailsListElementDescription
            salesOfficePageS2ListElementContactDetailsListElementImg {
              localFile {
                publicURL
              }
            }
          }
        }
        salesOfficePageS3Title
      }
    }
  }
`
